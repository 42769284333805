import Swiper from 'swiper';
import 'swiper/css';

export default function () {
    const swiper = new Swiper('.projects__container', {
        grabCursor: true,
        slidesPerView: 1.05,
        spaceBetween: 24,
        navigation: {
            nextEl: '#swiper-button-next-1',
            prevEl: '#swiper-button-prev-1',
        },
        breakpoints: {
            640: {
                slidesPerView: 2.05,
            },
            1290: {
                slidesPerView: 2,
            },
        },
    });

     // Adding an event listener for the 'slideNext' method
     document.querySelector('#swiper-button-next-1').addEventListener('click', () => {
        swiper.slideNext(300, () => {

        });
    });
     document.querySelector('#swiper-button-prev-1').addEventListener('click', () => {
        swiper.slidePrev(300, () => {

        });
    });

}
