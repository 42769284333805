/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import search from './components/search';
import initSlider from './components/slider';
import posts from './global/posts';

const $ = jQuery;

document.addEventListener('DOMContentLoaded', () => {
    svg4everybody();
    MobileMenu();
    search();
    posts();
    initSlider();


    if (document.querySelector('.mena-select')) {
        $('.mena-select').select2({
            width: 'resolve',
        });
    }
});
