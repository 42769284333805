// import Swiper from 'swiper';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/css';
Swiper.use([Autoplay]);

export default function initSlider() {
    const swiper = new Swiper('.hero__image__slider', {
        loop: false,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        slidesPerView: 1,
        speed: 1000,
        grabCursor: false,
        allowTouchMove: false,
        on: {
            init: function (swiperInstance) {
            },
            slideChange: function () {
            },
        },
    });
}
