import archive from './archive';
import newsSlider from '../components/newsSlider';
import projectSlider from '../components/projectSlider';

const $ = jQuery;

export default function () {
    searchPage();
    peoples();
    events();
    partners();
    projects();
    facility();
    publications();
    news();
}

function searchPage() {
    if ($('.content-search').length > 0) {
        const containers = {
            filters: '.content-search__filters',
            posts: '.content-search__container',
            archive: '.content-search',
        };

        archive(containers, 'getSearchPost');
    }
}

function peoples() {
    if ($('.people-archive').length > 0) {
        const containers = {
            filters: '.people-archive__filters',
            posts: '.people-archive__container',
            archive: '.people-archive',
        };

        archive(containers, 'menaGetPeopleAjax');
    }
}

function partners() {
    if ($('.partners-archive').length > 0) {
        const containers = {
            filters: '.partners-archive__filters',
            posts: '.partners-archive__container',
            archive: '.partners-archive',
        };

        archive(containers, 'menaGetPartnersAjax');
    }
}

function events() {
    if ($('.events-archive').length > 0) {
        const containers = {
            filters: '.events-archive__filters',
            posts: '.events-archive__container',
            archive: '.events-archive',
        };

        archive(containers, 'menaGetEventsAjax');
    }

    if (document.querySelector('.events__container')) {
        newsSlider('.events__container');
    }
}

function projects() {
    if ($('.project-archive').length > 0) {
        const containers = {
            filters: '.project-archive__filters',
            posts: '.project-archive__container',
            archive: '.project-archive',
        };

        archive(containers, 'menaGetProjectsAjax');
    }

    if (document.querySelector('.projects__container')) {
        projectSlider();
    }
}

function facility() {
    if ($('.facility-archive').length > 0) {
        const containers = {
            filters: '.facility-archive__filters',
            posts: '.facility-archive__container',
            archive: '.facility-archive',
        };
        archive(containers, 'menaGetfacilitiesAjax');
    }
}

function publications() {
    if ($('.publications-archive').length > 0) {
        const containers = {
            filters: '.publications-archive__filters',
            posts: '.publications-archive__container',
            archive: '.publications-archive',
        };

        archive(containers, 'menaGetPublicationsAjax');
    }

    if (document.querySelector('.publications__container')) {
        newsSlider('.publications__container');
    }
}

function news() {
    if ($('.news-archive').length > 0) {
        const containers = {
            filters: '.news-archive__filters',
            posts: '.news-archive__container',
            archive: '.news-archive',
        };

        archive(containers, 'menaGetNewsAjax');
    }

    if (document.querySelector('.news__container')) {
        newsSlider('.news__container');
    }
}
