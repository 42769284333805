import Swiper from 'swiper';
import 'swiper/css';

export default function (container) {
    const swiper = new Swiper(container, {
        // slidesPerView: "auto",
        // freeMode: true,
        // mousewheel: {
        //   releaseOnEdges: true,
        // },
        grabCursor: true,
        slidesPerView: 1.1,
        spaceBetween: 24,
        navigation: {
            nextEl: '#swiper-button-next-2',
            prevEl: '#swiper-button-prev-2',
        },
        breakpoints: {
            500: {
                slidesPerView: 2.1,
            },
            640: {
                slidesPerView: 3.1,
            },
            1024: {
                slidesPerView: 4.1,
            },
            1290: {
                slidesPerView: 4,
            },
        },
    });

      // Adding an event listener for the 'slideNext' method
      document.querySelector('#swiper-button-next-2').addEventListener('click', () => {
        swiper.slideNext(300, () => {

        });
    });
     document.querySelector('#swiper-button-prev-2').addEventListener('click', () => {
        swiper.slidePrev(300, () => {

        });
    });
}
