import {setActiveTerm} from '../components/filters';

const $ = jQuery;

export default function (containers, ajaxAction) {
    let filtersContainer = $(containers.filters);
    let postsContainer = $(containers.posts);
    let archive = $(containers.archive);
    let paginationContainer = $('.js-pagination-container');

    pagination(archive);
    getPostsSearchValue(filtersContainer);
    setActiveTerm(filtersContainer);

    $(filtersContainer).on('change', 'select', function (e) {
        getPosts();
    });

    function getPostsTerms(container) {
        let terms = new Object;
        let allSelects = $(container).find('select');

        allSelects.each(function (i, select) {
            let taxonomy = $(select).data('taxonomy');
            terms[taxonomy] = $(container).find(`#${taxonomy}`).val();
        });

        return terms;
    }

    function getPostsSearchValue(container) {
        const inputField = $(container).find('.js-search-archive');
        let timeout;

        inputField.on('input', function () {
            if (inputField.val().length === 0) {
                getPosts();
            }

            clearTimeout(timeout);

            if (inputField.val().length >= 2) {
                timeout = setTimeout(function () {
                    getPosts();
                }, 200);
            }
        });
    }

    function pagination(archive) {
        $(archive).on('click', '.page-numbers', function (e) {
            e.preventDefault();
            let pageNumber;

            if ((!$(this).hasClass('next')) && (!$(this).hasClass('prev'))) {
                pageNumber = $(this).text();
            } else {
                let activeNum = $(archive).find('.page-numbers.current');
                if ($(this).hasClass('next')) {
                    pageNumber = activeNum.next().text();
                }
                if ($(this).hasClass('prev')) {
                    pageNumber = activeNum.prev().text();
                }
            }

            pageNumber = parseInt(pageNumber);
            getPosts(pageNumber);
        });
    }

    function getPosts(paged = 1) {
        let data = new Object;
        let searchValue = $('.js-search-archive').val();
        data['terms'] = getPostsTerms(filtersContainer);
        data['paged'] = paged;

        if (searchValue) {
            data['s'] = searchValue;
        }

        getPostsAjax(data);
    }

    function getPostsAjax(data) {
        const loaderContainer = $('.js-archive--loader');

        $.ajax({
            type: 'GET',
            url: menaObject.ajaxurl,
            dataType: 'html',
            data: {
                action: ajaxAction,
                data: data,
            },

            beforeSend: function () {
                $(loaderContainer).addClass('loading');
            },

            success: function (response) {
                if (response) {
                    response = JSON.parse(response);

                    if (response.html) {
                        $(postsContainer).html(response.html);
                    }

                    if (response.pagination) {
                        $(paginationContainer).html(response.pagination);
                    } else {
                        $(paginationContainer).html('');
                    }

                    $(loaderContainer).removeClass('loading');

                    $('html, body').animate({
                        scrollTop: ($(postsContainer).offset().top - 200)
                    }, 50);
                }
            },
        });
    }
}
