/* eslint-disable */
const $ = jQuery;
export default function MobileMenu() {
	const mobileMenuToggles = document.querySelectorAll('.js-menu-toggle');

	if (!mobileMenuToggles) {
		return;
	}

	mobileMenuToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			const body = document.querySelector('body');
			body.classList.toggle('menu-open');
			$('.js-menu-toggle').toggleClass('menu-toggle--active');
		});
	});
}
