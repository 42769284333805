const $ = jQuery;

export function setActiveTerm(container) {
    let selects = $(container).find(`select`);
    $(selects).each(function (i, select) {
        let activeTermId = $(select).data('active-term');

        if (activeTermId) {
            $(container).find(`select[data-active-term="${activeTermId}"]`).val(activeTermId).trigger('change');
        }
    });
}
