export default function () {
    const headerSearch = document.querySelector('.header__search');
    const searchInput = headerSearch.querySelector('.e-search__input');
    const footerSearch = document.querySelector('.footer__search');

    function addActiveClass() {
        headerSearch.classList.add('active');
        searchInput.focus();
    }

    function removeActiveClass() {
        headerSearch.classList.remove('active');
    }

    headerSearch.addEventListener('click', function (event) {
        event.stopPropagation();
        addActiveClass();
    });

    document.addEventListener('click', function (event) {
        if (!headerSearch.contains(event.target)) {
            removeActiveClass();
        }
    });

    footerSearch.addEventListener('click', () => {
        setTimeout(function () {
            addActiveClass();
            searchInput.focus();
        }, 600)
    });
}
